0<template>
  <div>
    <div id="viewer"></div>
  </div>
</template>

<script>
// https://www.stimulsoft.com/en/samples
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'ReportViewer',
  store,
  props: {
    reportfilename: {
      type: String,
      default: () => '',
    },
    json_data: {
      type: Object,
      default: () => {},
    },
    json_url: {
      type: String,
      default: () => '',
    },
  },
  data () {
    return {
      can_render : false,
      viewer : null,
    }
  },
  beforeMount() {

  },
  created(){
    Stimulsoft.Base.StiLicense.Key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn0s4gy0Fr5YoUZ9V00Y0igCSFQzwEqYBh/N77k4f0fWXTHW5rqeBNLkaurJDenJ9o97TyqHs9HfvINK18Uwzsc/bG01Rq+x3H3Rf+g7AY92gvWmp7VA2Uxa30Q97f61siWz2dE5kdBVcCnSFzC6awE74JzDcJMj8OuxplqB1CYcpoPcOjKy1PiATlC3UsBaLEXsok1xxtRMQ283r282tkh8XQitsxtTczAJBxijuJNfziYhci2jResWXK51ygOOEbVAxmpflujkJ8oEVHkOA/CjX6bGx05pNZ6oSIu9H8deF94MyqIwcdeirCe60GbIQByQtLimfxbIZnO35X3fs/94av0ODfELqrQEpLrpU6FNeHttvlMc5UVrT4K+8lPbqR8Hq0PFWmFrbVIYSi7tAVFMMe2D1C59NWyLu3AkrD3No7YhLVh7LV0Tttr/8FrcZ8xirBPcMZCIGrRIesrHxOsZH2V8t/t0GXCnLLAWX+TNvdNXkB8cF2y9ZXf1enI064yE5dwMs2fQ0yOUG/xornE";
  },
  mounted () {
    const self = this;


    // Specify necessary options for the viewer
    let options = new Stimulsoft.Viewer.StiViewerOptions();
    // options.height = "100%";
    options.toolbar.showDesignButton = false;
    options.toolbar.showOpenButton = false;
    options.toolbar.showAboutButton = false;
    options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;
    // options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageHeight;
    options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;

    options.appearance.scrollbarsMode = false;
    options.appearance.showTooltipsHelp = false;
    options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Table;

    if(self.$data.viewer == null){
      self.$data.viewer = new window.Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    }


    if(self.isJsonString(JSON.stringify(self.$props.json_data))) {
      // https://www.stimulsoft.com/en/samples/reports-js/javascript/registering-a-json-data-for-the-report-template


      let report = new window.Stimulsoft.Report.StiReport();
      report.loadFile(self.reportfilename);
      report.dictionary.databases.clear();
      // report.dictionary.clear();

      //https://www.codeproject.com/Questions/614038/Pass-a-value-to-Stimulsoft-report-parameter

      //report.dictionary.variables.getByName("rep_date").valueObject  = new Stimulsoft.Report.Dictionary.StiVariable("rep_date","30-10-1978")
      // alert(report.dictionary.variables.getByName("rep_date").valueObject );

      let dataSet = new Stimulsoft.System.Data.DataSet("SimpleDataSet");
      dataSet.readJson(self.$props.json_data);

      report.regData("SimpleDataSet", "SimpleDataSet", dataSet);
      report.dictionary.synchronize();


      // report.dictionary.variables.getByName("rep_date").valueObject = Date();

      self.$data.viewer.report = report;
      self.$data.viewer.renderHtml('viewer');





      // report.renderAsync(function () {
      //   let pageRange = new Stimulsoft.Report.StiPagesRange(Stimulsoft.Report.StiRangeType.FirstPage,"1",1);
      //   report.print(pageRange);
      // });
    }
  },
  methods:{
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    printReport() {
      const self =this;
      let report = new Stimulsoft.Report.StiReport();
      // Load report from url
      report.loadFile(this.$props.reportfilename);
      // Render report

      report.renderAsync(function () {
        // Print report using web browser
        report.print();
      });
    },

  },

}
</script>

<style scoped>

</style>
